<template>
  <b-row>
    <b-modal
      id="modal-city"
      centered
      :title="$t('Pages.City.Title.req_add')"
      ok-only
      no-close-on-backdrop
      content-class="shadow"
      :ok-title="$t('Pages.City.Button.Save')"
      @ok="save"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col lg="12" sm="12" class="mb-2">
            <b-alert show dismissible fade class="mb-0" variant="success">
              <div class="alert-body">
                <span>{{ $t("Pages.City.Title.approve_by_admin") }}</span>
              </div>
            </b-alert>
          </b-col>

          <b-col cols="12">
            <b-form-group
              :label="$t('Pages.City.Label.select_ch')"
              label-for="h-title"
              label-cols-md="4"
            >
              <v-select
                id="h-channel"
                v-model="selectedChannel"
                :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'"
                label="nickname"
                value="channel_id"
                :options="userChannels"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('Pages.City.Label.select_cat')"
              label-for="h-category"
              label-cols-md="4"
            >
              <v-select
                id="h-category"
                v-model="selectedCategory"
                :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'"
                :options="categories"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ///////start filter section/////// -->
    <b-col lg="12">
      <b-row class="d-flex justify-content-around text-center">
        <b-col lg="2" sm="12" class="pr-0 mx-0">
          <b-card class="col-12 ch_num_card">
            <b-card-body class="my-0 px-0">
              <b-row>
                <b-col xl="12" sm="12" class="mx-auto">
                  <b-avatar class="mt-1" size="48" variant="light-success">
                    <feather-icon size="24" icon="GlobeIcon" />
                  </b-avatar>
                  <b-card-text class="font-small-3">
                    <h4 class="font-weight-bolder mt-1">
                      {{ channelOptions.length }}
                    </h4>
                    <div class="pb-2">
                      <span class="pb-2">{{
                        $t("Pages.City.Title.ch_num")
                      }}</span>
                    </div>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col lg="10" sm="12">
          <b-card class="col-12">
            <b-card-body class="my-0 py-1">
              <b-row>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                  <label>{{ $t("Pages.City.Label.select_cat") }}</label>
                  <v-select
                    :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'"
                    :options="categories"
                    class="w-100"
                    v-model="categoryFilter"
                    v-on:input="filterChannel"
                  />
                </b-col>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                  <label>{{ $t("Pages.City.Label.search_ch") }}</label>
                  <input
                    :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'"
                    class="w-100 form-control"
                    v-model="nameChaneel"
                    @keydown="filterChannel"
                  />
                </b-col>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                  <div class="form-group">
                    <label for="customSelect">{{
                      $t("Pages.City.Label.sort_by")
                    }}</label>
                    <select
                      class="custom-select"
                      id="customSelect"
                      v-model="sort"
                      @change="filterChannel"
                    >
                      <option value="default" selected="selected">
                        {{ $t("Pages.City.Label.Default") }}
                      </option>
                      <option value="most subscribers">
                        {{ $t("Pages.City.Label.most_sub") }}
                      </option>
                      <option value="most videos">
                        {{ $t("Pages.City.Label.most_video") }}
                      </option>
                      <option value="least subscribers">
                        {{ $t("Pages.City.Label.least_sub") }}
                      </option>
                      <option value="least videos">
                        {{ $t("Pages.City.Label.least_video") }}
                      </option>
                    </select>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <!-- /////start of channels-grid///// -->
    <b-col sm="12" class="mt-1">
      <b-row>
        <!-- ////start of sticky channel///// -->
        <b-col
          lg="3"
          sm="12"
          v-if="stickyChannels.length > 0"
          v-for="stickyChannel in stickyChannels"
          :key="stickyChannel.id"
        >
          <b-link
            class="cursor-pointer"
            :to="{ path: '/city/channel', query: { id: stickyChannel.id } }"
          >
            <b-card
              :img-src="stickyChannel.banner"
              img-alt="Profile Cover Photo"
              img-top
              class="card-profile"
            >
              <div class="profile-image-wrapper">
                <div class="profile-image p-0">
                  <b-avatar
                    size="114"
                    variant="light"
                    :src="stickyChannel.avatar"
                  />
                </div>
              </div>
              <h3>{{ stickyChannel.nickname }}</h3>
              <h6 class="text-muted">
                {{ MyDate.getFormatDate(stickyChannel.publishedAt) }}
              </h6>
              <!-- /////category-badge///// -->
              <b-badge class="profile-badge" variant="light-primary">
                {{ stickyChannel.category }}
              </b-badge>
              <hr class="mb-2" />

              <!-- Subscribers projects rank -->
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="text-muted font-weight-bolder">
                    {{ $t("Pages.City.Title.Videos") }}
                  </h6>
                  <h3 class="mb-0">{{ stickyChannel.videos }}</h3>
                </div>
                <div>
                  <h6 class="text-muted font-weight-bolder">
                    {{ $t("Pages.City.Title.Subscriber") }}
                  </h6>
                  <h3 class="mb-0">{{ stickyChannel.subscribers }}</h3>
                </div>
              </div>
            </b-card>
          </b-link>
        </b-col>
        <!-- //////end of sticky channel//// -->
        <!-- ////////start of channels list grid//// -->
        <b-col lg="3" sm="12" v-for="channel in channelsList" :key="channel.id">
          <b-link
            class="cursor-pointer"
            :to="{ path: '/city/channel', query: { id: channel.id } }"
          >
            <b-card
              :img-src="channel.banner"
              img-alt="Profile Cover Photo"
              img-top
              class="card-profile"
            >
              <div class="profile-image-wrapper">
                <div class="profile-image p-0">
                  <b-avatar size="114" variant="light" :src="channel.avatar" />
                </div>
              </div>
              <h3>{{ channel.nickname }}</h3>
              <h6 class="text-muted">
                {{ MyDate.getFormatDate(channel.publishedAt) }}
              </h6>
              <!-- /////category-badge///// -->
              <b-badge class="profile-badge" variant="light-primary">
                {{ channel.category }}
              </b-badge>
              <hr class="mb-2" />

              <!-- Subscribers projects rank -->
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="text-muted font-weight-bolder">
                    {{ $t("Pages.City.Title.Videos") }}
                  </h6>
                  <h3 class="mb-0">{{ channel.videos }}</h3>
                </div>
                <div>
                  <h6 class="text-muted font-weight-bolder">
                    {{ $t("Pages.City.Title.Subscriber") }}
                  </h6>
                  <h3 class="mb-0">{{ channel.subscribers }}</h3>
                </div>
              </div>
            </b-card>
          </b-link>
        </b-col>
        <!-- ////pagination controller//// -->
        <b-col
          cols="12"
          sm="12"
          v-if="pages > 1"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="pages"
            use-router
            class="mb-0"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardBody,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BLink,
  BPaginationNav,
  BModal,
  BAlert,
  BFormGroup,
  BCardText,
  BForm,
} from "bootstrap-vue";
import MyDate from "@core/components/date/MyDate.vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BLink,
    vSelect,
    BCardBody,
    BPaginationNav,
    BModal,
    BAlert,
    BFormGroup,
    BCardText,
    BForm,
  },
  data() {
    return {
      MyDate,
      channelsList: [],
      stickyChannels: [],
      pages: 0,
      channelOptions: [],
      channelFilter: {
        label: "",
        value: 0,
      },
      categoryFilter: {
        label: "",
        value: 0,
      },
      sort: "default",
      category: "",
      nameChaneel: "",
      userChannels: [],
      selectedChannel: { nickname: "", channel_id: "" },
      categories: [],
      selectedCategory: {
        label: "",
        value: 0,
      },
      language: "fa",
      count_coin: 0,
    };
  },
  created() {
    this.language = this.$i18n.locale;
    this.getCountCoin();
    this.getStickyChannel();
    this.getListAll();
    this.getListAllChannel();
    this.getListAllCategory();
    this.getChannelList();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getNewData();
        // this.getListAll();
      }
    },
  },
  methods: {
    makeToast(title, text, variant = null) {
      this.$bvToast.toast(text, {
        title: title,
        variant,
        solid: true,
      });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    getNewData() {
      if (this.categoryFilter.value != 0) {
        this.filterChannel();
      } else if (this.nameChaneel != "") {
        this.filterChannel();
      } else {
        this.filterChannel();
      }
    },
    getListAllCategory() {
      this.$http.get("/api/city/category/list").then((response) => {
        this.categories = response.data.result;
      });
    },
    ////list of current user  that are used in modal////
    getChannelList() {
      this.$http.get("/api/channel/list").then((response) => {
        this.userChannels = response.data.list_channels.data;
      });
    },
    // the number of all channels of the city
    getListAllChannel() {
      this.$http.get("/api/city/channel/list/mini").then((response) => {
        this.channelOptions = response.data;
      });
    },
    ////get sticky channel////
    async getStickyChannel() {
      try {
        const response = await this.$http.get("/api/city/channel/sticky");
        const { result } = response.data;
        this.stickyChannels = result;
      } catch (error) {
        console.error("Error fetching channel sticky:", error);
      }
    },
    ////pagination /////
    async getListAll() {
      try {
        const response = await this.$http.get("/api/city/channel/list", {
          params: {
            page: this.$route.query.page,
          },
        });
        const { result } = response.data;
        this.updateChannelsList(result);
      } catch (error) {
        console.error("Error fetching channel list:", error);
      }
    },

    updateChannelsList(result) {
      this.channelsList = result.data;
      this.pages = result.last_page;
    },
    ////filter channels /////
    filterChannel() {
      let sort = this.sort != "default" ? this.sort : "default";
      let category =
        this.categoryFilter.value != 0 ? this.categoryFilter.value : "default";
      let nameChaneel = this.nameChaneel != "" ? this.nameChaneel : "default";
      this.$http
        .get(
          `/api/city/channel/filter?category=${category}&sort=${sort}&name=${nameChaneel}`,
          {
            params: {
              page: this.$route.query.page,
            },
          }
        )
        .then((response) => {
          const { result } = response.data;
          this.channelsList = result.data;
          this.pages = result.last_page;
          this.channelFilter = {
            label: "",
            value: 0,
          };
        });
    },
    getCountCoin() {
      this.$http.get(`/api/dashboard/getCountCoin`).then((response) => {
        this.count_coin = response.data.count_coin;
      });
    },
    save() {
      this.$http.get("/api/user/setting/check-verify").then((response) => {
        if (response.data && response.data.status) {
          if (
            this.selectedChannel.channel_id == "" ||
            this.selectedCategory.value == 0
          ) {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.comp_info"),
              "danger"
            );
          } else {
            if (this.count_coin >= 9) {
              const payload = {
                channel_link: this.selectedChannel.channel_id,
                channel_category: this.selectedCategory.value,
              };
              this.$http
                .post(`/api/city/add`, payload)
                .then((resp) => {
                  if (resp.data.status == true) {
                    this.makeToast(
                      this.$t("Base.Alert.Successful"),
                      this.$t("Base.Alert.info_saved"),
                      "success"
                    );
                    this.channelName = "";
                    this.channelLink = "";
                    this.$root.$emit("updateCoin", true);
                  } else {
                    this.makeToast(
                      this.$t("Base.Alert.Error"),
                      this.$t("Base.Alert.req_review_error"),
                      "danger"
                    );
                  }
                })
                .catch(() => {
                  this.makeToast(
                    this.$t("Base.Alert.Error"),
                    this.$t("Base.Alert.comp_info"),
                    "danger"
                  );
                });
            } else {
              this.$swal({
                title: this.$t("Base.Alert.Error"),
                text: this.$t("Base.Alert.not_enough_coin"),
                icon: "warning",
                confirmButtonText: this.$t("Pages.MasterFile.Title.Buy"),
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {
                  this.$router.push({ name: "MasterCoin" }).catch(() => {});
                }
              });
            }
          }
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.verify_email_phone"),
            "danger"
          );
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.ch_num_card {
  .card-body {
    padding: 0 !important;
  }
}
</style>
